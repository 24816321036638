import React from "react";
import Layout from "../components/layout";
import { Card, Row, Col, Image, ListGroup, ListGroupItem } from "react-bootstrap";
import FutureImg from "../images/futuro.png"
import Seo from "../components/seo";

const Savings2 = () => (
    <Layout>
        <Seo title="Ahorro futurito" description="Describe ahorro futurito en CoopGestión" />
        <Row>
            <Col>
                <div className="jumbotron bg-secondary text-white">
                    <h1 className="display-4 jumbotron-title">AHORRO FUTURITO</h1>
                    <p className="lead">
                        Ahorrando hoy, para cumplir tus sueños mañana.
                    </p>
                </div>
            </Col>
        </Row>
        <Row className="my-3">
            <Col sm className="m-auto">
                <Card>
                    <Card.Header>Ahorro Futurito</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            Pensando en ahorrar para los estudios de tus hijos, planificar
                            un viaje o quieres ahorrar para comprarte un automovil o emprender tu
                            propio negocio.
                        </Card.Text>
                        <Card.Title>
                            Requisitos
                        </Card.Title>
                        <ListGroup variant="flush">
                            <ListGroupItem>&#x2022;  Apertura con solo 30 USD</ListGroupItem>
                            <ListGroupItem>&#x2022;  Cédula de identidad</ListGroupItem>
                            <ListGroupItem>&#x2022;  Papeleta de votación</ListGroupItem>
                            <ListGroupItem>&#x2022;  Planilla de servicio básico</ListGroupItem>
                        </ListGroup>
                        {/* <Card.Text>
                            <ul>
                                <li>Apertura con solo 30 USD </li>
                                <li>Cédula de identidad</li>
                                <li>Papeleta de votación</li>
                                <li>Planilla de servicio básico</li>
                            </ul>
                        </Card.Text> */}
                    </Card.Body>
                </Card>
            </Col>
            <Col sm className="m-auto py-1 text-center">
                <Image src={FutureImg} alt="Ahorro futurito" fluid />
            </Col>
        </Row>
    </Layout>
);

export default Savings2;